<template>
	<div class="home h-100">
		<installPrompt></installPrompt>
		<button v-if="updateExists"
				class="btn btn-hero-success btn-block mb-4"
				@click="refreshApp">
			Une nouvelle version est disponible ! Cliquez ici
		</button>
		<div class="row h-100">
			<div class="col">
				<div class="d-flex my-4">
					<div class="col">
						<img src="/img/logov2.svg" style="width:150px;max-height:100px;">
					</div>
					<div class="col flex-fill">
						{{config.user? config.user.username : ''}}<br>
						{{config.user? config.user.firm : ''}}
					</div>
				</div>
				<a href="#" @click.prevent="newSAV" class="btn btn-primary mb-4 btn-block">Nouveau SAV</a>


				<div v-for="(inter) in getInterventions" :key="'inter-'+inter.uid">
					<ul class="list-group mb-3" v-if="inter.responses.sav">
						<li @click="clickSav(inter)"
							:key="inter.uid" class="list-group-item shadow">
							<div class="d-flex justify-content-between">
								<div>
									<div class="font-size-h3 mb-2">{{inter.responses.sav.societelibelle}}</div>
									<div>{{familles[inter.responses.sav.familleid]}}</div>
								</div>
								<div class="font-size-sm">
									<span class="badge badge-pill ml-2" :class="{'badge-danger': inter.sync===0, 'badge-success': inter.sync===1, 'badge-warning': inter.sync===2}">&NoBreak;</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import installPrompt from '@/components/installPrompt.vue';
	import {mapActions, mapGetters} from 'vuex'
	
	export default {
		name: 'home',
		components: {
			installPrompt
		},
		data: function () {
			return {
				refreshing: false,
				registration: null,
				updateExists: false
			}
		},
        created() {
			// Listen for swUpdated event and display refresh snackbar as required.
			document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
			// Refresh all open app tabs when a new service worker is installed.
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('config', {'config': 'getConfig', 'familles': 'getFamilles'}),
			...mapGetters('interventions', {'interventions': 'getAll'}),
			getInterventions(){
				return _.reverse(_.sortBy(this.interventions, 'uid'));
			}
		},
		methods: {
			...mapActions('interventions', ['setIntervention']),
			newSAV(){
				// let eventid = this.config.user.uid+"_"+ (new Date).getTime(); 
				let eventid = ""+(new Date).getTime(); 
				this.setIntervention({uid: eventid, data: {}})
				this.$router.push({name: 'event', params: {eventid: eventid}});
			},
			clickSav(sav){

				this.$router.push({name: 'event', params: {eventid: sav.uid}});
			},
			showRefreshUI(e) {
				// Display a button inviting the user to refresh/reload the app due
				// to an app update being available.
				// The new service worker is installed, but not yet active.
				// Store the ServiceWorkerRegistration instance for later use.
				this.registration = e.detail;
				this.updateExists = true;
			},
			refreshApp() {
				// Handle a user tap on the update app button.
				this.updateExists = false;
				// Protect against missing registration.waiting.
				if (!this.registration || !this.registration.waiting) {
					return;
				}
				this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
			},
		},
	}
</script>
