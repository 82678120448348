<template>
	<div class="d-flex flex-column h-100">
		<div class="flex-grow-1">
			<b-form-group class="my-2" label="Client" label-for="societeid">
				<b-input-group v-if="!response.societeid">
					<v-select @search="onSearchClients" :class="{ 'is-invalid': errors.societeid }" id="societeid"
						placeholder="Saisissez un client" @input="selectSociete"
						:value="response.societeid" class="form-control" :options="optionsClients" :filterable="false" />
				</b-input-group>
				<div class="d-flex justify-content-between" v-if="response.societeid">
					<div>{{response.societelibelle}} </div>
					<a href="#" @click.prevent="response.societeid=null;response.societelibelle = ''" class="ml-4 btn btn-sm btn-danger">
						<i class="fa fa-times"></i>
					</a>
				</div>
				<div v-if="errors.societeid" class="text-danger">
					{{ errors.societeid }}
				</div>
			</b-form-group>

			<b-form-group class="my-2" label="Marque" label-for="familleid">
				<b-input-group>
					<b-form-select
						id="familleid"
						type="text"
						:class="{ 'is-invalid': errors.familleid }"
						v-model="response.familleid"
					>
					<b-form-select-option :value="null">Sélectionnez une marque</b-form-select-option>
					<b-form-select-option v-for="(name, id) in familles" :value="id" :key="'famille-'+id">{{name}}</b-form-select-option>
					</b-form-select>
				</b-input-group>
				<div v-if="errors.familleid" class="text-danger">
					{{ errors.familleid }}
				</div>
			</b-form-group>

			<b-form-group class="my-2" label="Article" label-for="articleid">
				<b-input-group v-if="!response.articleid">
					<v-select @search="onSearchArticles" :class="{ 'is-invalid': errors.articleid }" id="articleid"
						placeholder="Saisissez un article par sa référence" @input="selectArticle"
						:value="response.articleid" class="form-control" :options="optionsArticles"  :filterable="false"/>
				</b-input-group>
				<div class="d-flex justify-content-between" v-if="response.articleid">
					<div>{{response.articlelibelle}} </div>
					<a href="#" @click.prevent="response.articleid=null;response.articlelibelle = ''" class="ml-4 btn btn-sm btn-danger">
						<i class="fa fa-times"></i>
					</a>
				</div>
				<div v-if="errors.articleid" class="text-danger">
					{{ errors.articleid }}
				</div>
			</b-form-group>

			<pictures :uid="eventid" responseid="sav" name="pictures" label="" class="mb-2 mt-5" />

			<br />

			<div class="form-group my-2 required">
				<label for="commentaire">Commentaire</label>

				<textarea
					class="form-control"
					required
					v-model="response.commentaire"
					rows="8"
					:class="{ 'is-invalid': errors.commentaire }"
					name="commentaire"
					id="commentaire"
				></textarea>
				<div v-if="errors.commentaire" class="invalid-feedback">
					{{ errors.commentaire }}
				</div>
			</div>

			<b-form-group class="my-2" label="Nom contremarque" label-for="contremarquenom">
				<b-input-group>
					<b-form-input
						id="contremarquenom"
						type="text"
						:class="{ 'is-invalid': errors.contremarquenom }"
						v-model="response.contremarquenom"
					></b-form-input>
				</b-input-group>
				<div v-if="errors.contremarquenom" class="text-danger">
					{{ errors.contremarquenom }}
				</div>
			</b-form-group>

			<b-form-group class="my-2" label="Numéro contremarque" label-for="contremarquenumero">
				<b-input-group>
					<b-form-input
						id="contremarquenumero"
						type="text"
						:class="{ 'is-invalid': errors.contremarquenumero }"
						v-model="response.contremarquenumero"
					></b-form-input>
				</b-input-group>
				<div v-if="errors.contremarquenumero" class="text-danger">
					{{ errors.contremarquenumero }}
				</div>
			</b-form-group>

			<div class="row mt-4">
				<div class="col">
					<div class="btn btn-block btn-primary" @click="nextPage()">
						{{ $t("Submit") }}
					</div>
				</div>
			</div>
		</div>
		<br />
	</div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
import Pictures from "@/components/Pictures.vue";
import api from '@/services/api';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default {
	name: "event",
	props: {
		eventid: String,
		responseid:{
			default: 'sav'
		},
	},
	data: function() {
		return {
			form: [],
			errors: {},
			response: {
				societeid: null,
				familleid: null,
				articleid: null,
				societelibelle: '',
				articlelibelle: '',
				commentaire: "",
				contremarquenom: "",
			},
			clients: {},
			articles: {},
			totalPages: 1,
		};
	},
	components: { Pictures, vSelect },
	computed: {
		...mapGetters('interventions', [
			'getResponses'
		]),
		...mapGetters('config', {'familles': 'getFamilles'}),
		optionsClients(){
			let options = [];
			 _.forIn(this.clients, (value, key)=>{
				options.push({
					label: value,
					id: key
				})
			})
			return options;
		},
		optionsArticles(){
			let options = [];
			 _.forIn(this.articles, (value, key)=>{
				options.push({
					label: value.ref + ' : ' + value.libelle,
					id: key
				})
			})
			return options;
		},
	},
	created: function() {
		this.$route.meta.title = "SAV";
		this.response = this.getResponses({uid: this.eventid, responseid: this.responseid})

		this.form = [
			{
				Type: "text",
				Name: "societeid",
				Required: true,
			},
			{
				Type: "text",
				Name: "familleid",
				Required: true,
			},
			{
				Type: "text",
				Name: "articleid",
				Required: true,
			},
			{
				Type: "textarea",
				Name: "commentaire",
				Required: true,
			},
			{
				Type: "text",
				Name: "contremarquenom",
				Required: false,
			},
			{
				Type: "text",
				Name: "contremarquenumero",
				Required: false,
			},
		];
	},
	methods: {
		...mapActions("interventions", ["setResponse", "finishIntervention"]),
		onSearchClients(search, loading) {
			if (search.length && search.length > 2){
				loading(true);
				this.fetchClients(loading, search, this);
			}
		},
		fetchClients: _.debounce(async function(loading, search, vm) {			
			let result = await api.post({
				url: 'intervention/v3/clients',
				params: {
					search: search
				}
			});
			loading(false);
			vm.clients = result.response;
		},350),
		selectSociete(value){			
			this.response.societeid = value.id;
			this.response.societelibelle = value.label;
		},
		onSearchArticles(search, loading) {
			if (this.response.familleid && search.length > 3){
				loading(true);
				this.fetchArticles(loading, search, this);
			}
		},
		fetchArticles: _.debounce(async function(loading, search, vm) {			
			let result = await api.post({
				url: 'intervention/v3/'+vm.response.familleid+'/articles',
				params: {
					search: search
				}
			});
			loading(false);
			vm.articles = result.response;
		},350),
		selectArticle(value){
			this.response.articleid = value.id;
			this.response.articlelibelle = value.label;
		},
		prevPage: function() {
			this.$router.push({ name: "home" });
		},
		nextPage: function() {
			Dashmix.layout("header_loader_on");
			let self = this;
			self.errors = {};
			this.form.map((item) => {
				if (
					item.Required &&
					item.Type == "multiselect" &&
					self.response[item.Name].length == 0
				) {
					self.errors[item.Name] = self.$t("Required input");
				} else if (item.Required && !self.response[item.Name]) {
					self.errors[item.Name] = self.$t("Required input");
				}
			});

			if (Object.keys(self.errors).length > 0) {
				Dashmix.layout("header_loader_off");
				return;
			}

			this.response.finished = true;
			this.setResponse({
				uid: this.eventid,
				responseid: this.responseid,
				data: this.response,
			});
			this.finishIntervention({ uid: this.eventid });
			this.$router.push({ name: "home" });
		},
	},
};
</script>

<style>
    .border-square .vs__dropdown-toggle{
        border-radius: 0;
    }
    .vs__dropdown-toggle {
        border: none;
    }
    .vs__actions {
        display: none;
    }
</style>