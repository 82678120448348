<template>
	<div class="h-100">
		<layout-default class="h-100" v-if="$route.meta.logLayout===false"></layout-default>
		<layout-log v-if="$route.meta.logLayout"></layout-log>
	</div>
</template>

<script>
	
    import {TaskWorker} from '@/services/task';

	import LayoutLog from '@/views/layout/Log';
	import LayoutDefault from '@/views/layout/Default';
	
	export default {
		components: {
			LayoutLog,
			LayoutDefault,
		},
		created: function () {
			this.initTaskWorker();
		},
		
		methods: {
			initTaskWorker: function () {
				const taskWorker = new TaskWorker(process.env.VUE_APP_TASK_WORKER_INTERVAL);
				taskWorker.init();
			}
		}
	}

</script>
